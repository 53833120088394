@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.tracking-card {
  display: flex;
  width: 60%;
  height: 100%;
  background: #ffffff;
  border-radius: 0%;
  border: #ffffff;

  @media (max-width: 550px) {
    width: 100%;
    background: #ffffff;
  }

  &__images {
    &--processingOrder {
      width: 82px;
      height: 85px;
    }

    &--assembled {
      width: 69px;
      height: 72px;
    }

    &--returnToWarecloud {
      width: 95px;
      height: 85px;
    }

    &--inRouteToConsumer {
      width: 95px;
      height: 85px;
    }

    &--deliveredToConsumer {
      width: 77px;
      height: 79px;
    }
  }

  &__status {
    color: #5a37b1;

    @media (max-width: 436px) {
      font-size: small;
    }

    &--pending {
      color: #b1b1b1;

      @media (max-width: 436px) {
        font-size: small;
      }
    }

    &--return {
      color: #e21a1a;

      @media (max-width: 436px) {
        font-size: small;
      }
    }
  }

  &__line {
    height: 25px;
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent calc(50% - 1.7px),
      #5a37b1 calc(50% - 1.7px),
      #5a37b1 calc(50% + 1.7px),
      transparent calc(50% + 1.7px),
      transparent 100%
    );

    &--pending {
      height: 25px;
      background: linear-gradient(
        to right,
        transparent 0%,
        transparent calc(50% - 1.7px),
        #b1b1b1 calc(50% - 1.7px),
        #b1b1b1 calc(50% + 1.7px),
        transparent calc(50% + 1.7px),
        transparent 100%
      );
    }

    &--return {
      height: 20px;
      background: linear-gradient(
        to right,
        transparent 0%,
        transparent calc(50% - 2px),
        #e21a1a calc(50% - 2px),
        #e21a1a calc(50% + 2px),
        transparent calc(50% + 2px),
        transparent 100%
      );
    }
  }
}

.timestamp {
  color: #5a37b1;
  font-size: 13px;
  font-weight: 400;

  @media (max-width: 436px) {
    font-size: 10px;
  }

  &__return {
    color: #e21a1a;
    font-size: 13px;
    font-weight: 400;

    @media (max-width: 436px) {
      font-size: 10px;
    }
  }
}

.layout-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 200vh;
}

.layout-text {
  font-size: 22px;
  color: #5a37b1;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 130px;
  justify-content: left;
}

.layout-sub-text {
  font-size: 16px;
  color: #000000;
  margin-top: 12px;
}
.layout-text-sub {
  font-size: 14px;
  font-weight: bold;
}
.text-sub {
  font-size: 15px;
  color: #909990;
  font-weight: 100;
}
.text-sub-label {
  font-size: 16px;
  font-weight: 100;
  color: #060706;
}
.referral-button {
  background-color: #6c4fc6;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  max-width: 80px;
  font-size: 0.875rem;
  text-align: center;
}

.tracking-card {
  padding: 20px;
}

h3.layout-text {
  color: #3f20ba;
  font-weight: bold;
  margin-bottom: 10px;
}

h5.layout-sub-text {
  color: #333;
  text-align: left;
  margin: 0 20px;
  line-height: 1.5;
}

.tracking-card__status {
  font-size: 14px;
}

.timestamp {
  font-size: 12px;
  color: #999;
}
.text-left {
  text-align: left;
}

.tracking-card__line-horizontal {
  width: 100%;
  height: 2px;
  background-color: #6c4fc6;
  margin: 0 auto;
}

.tracking-card__line-horizontal--pending {
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 0 auto;
}

.status-circle {
  background-color: #6c4fc6;
  border-radius: 50%;
  padding: 10px;
  display: inline-block;
}
.status-circle {
  background-color: #6c4fc6;
  border-radius: 50%;
  padding: 10px;
  display: inline-block;
}
.tracking-card__images--processingOrder,
.tracking-card__images--assembled,
.tracking-card__images--inRouteToConsumer,
.tracking-card__images--deliveredToConsumer {
  width: 40px;
  height: 40px;
}

.help-button,
.referral-button {
  background-color: #6c4fc6;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  max-width: 200px;
  text-align: center;
}

.help-button:hover,
.referral-button:hover {
  background-color: #5a3db3;
  color: #131218;
}

.footer-content {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-section {
  margin: 0 15px;
}

.footer-section img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.footer-social-section {
  background-color: white;
  padding: 50px 0;
  text-align: center;
}

.footer-social {
  display: flex;
  justify-content: center;
}

.footer-social img {
  width: 50px;
  height: 50px;
  margin: 0 10px;
}

.circle {
  width: 62px;
  height: 62px;
  background-color: #5938bd;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  z-index: 1;
}
.circle-g {
  width: 62px;
  height: 62px;
  background-color: #b3a6dd;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  z-index: 1;
}
.order-info-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.footer-social-section {
  margin-top: 20px;
  text-align: center;
}

.footer-social img {
  margin: 0 10px;
}
.tracking-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

.circle img {
  width: 80px;
  height: 80px;
  margin-bottom: 4px;
}

.text {
  margin-top: 10px;
}

.date {
  font-size: 0.8em;
  margin-top: 5px;
}

.step-text {
  text-align: center;
  margin-top: 10px;
}

.step::after {
  content: "";
  position: absolute;
  top: 25%;
  right: -50%;
  width: 100%;
  height: 7px;
  background-color: #5938bd;
  z-index: 0;
}
.step-g::after {
  content: "";
  position: absolute;
  top: 25%;
  right: -50%;
  width: 100%;
  height: 7px;
  background-color: #b3a6dd;
  z-index: 0;
}

.step:last-child::after {
  display: none;
}
.step-g:last-child::after {
  display: none;
}

.step-g {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 px;
  position: relative;
}
.text-category {
  color: #5938bd;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
  @media (max-width: 450px) {
    font-size: 12px;
  }
}
.text-category-sub {
  color: #5938bd;
  font-size: 14px;
  @media (max-width: 450px) {
    font-size: 10px;
  }
}
.text-category-inactive {
  color: #b3a6dd;
  font-size: 14px;
  font-weight: bold;
  padding: 4px;
  @media (max-width: 450px) {
    font-size: 12px;
  }
}
.text-category-sub-inactive {
  color: #b3a6dd;
  font-size: 14px;
  @media (max-width: 450px) {
    font-size: 10px;
  }
}
.void-sub {
  height: 19px;
  @media (max-width: 450px) {
    height: 13px;
  }
  @media (min-width: 850px) {
    height: 20px;
  }
}
.text-category-red {
  color: #e03434;
  font-size: 15px;
  font-weight: bold;
  @media (max-width: 450px) {
    font-size: 10px;
  }
}
.text-category-red-sub {
  color: #e03434;
  font-size: 14px;
  @media (max-width: 450px) {
    font-size: 10px;
  }
}
.circle-return {
  width: 62px;
  height: 62px;
  background-color: #e03434;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  z-index: 1;
}

.circle-return img {
  width: 80px;
  height: 80px;
  margin-bottom: 4px;
}
.circle-g img {
  width: 80px;
  height: 80px;
  margin-bottom: 4px;
}
